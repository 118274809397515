import ContactUs from '../contact-us/Contact.component';
import './contact-route.scss';
import { BiPhoneCall } from 'react-icons/bi';
const ContactRoute = () => {
  return (
    <div className="text-container-about-us">
      <div className="text-container-about-us-second">
        <div className="logo-text">
          <img
            className="logo-container"
            alt="logo"
            src="https://s3.us-west-2.amazonaws.com/moveviking.com/images/logo-viking-footer.png"
          ></img>
          <h2>Get Started With a Free Quote</h2>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: 0,
              gap: '20px',
            }}
          >
            <button>
              {' '}
              <a
                href="tel:+18188454647"
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                  }}
                >
                  <BiPhoneCall style={{ fontSize: '26px', color: 'white' }} />
                  <span className="phone-number"> (818) 845 46 47</span>
                </div>
              </a>
            </button>
            <a
              href="mailto:vikingmovingandrelocation@gmail.com"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 10,
                }}
              >
                <span
                  className="email-icon"
                  style={{ fontSize: '26px', color: 'white', paddingTop: 5 }}
                >
                  ✉️
                </span>
                <span className="email">
                  vikingmovingandrelocation@gmail.com
                </span>
              </div>
            </a>
          </div>
        </div>
        <div style={{}}>
          <ContactUs />
        </div>
      </div>
    </div>
  );
};

export default ContactRoute;
