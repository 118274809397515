import './footer.style.scss';
import { GoLocation } from 'react-icons/go';
import { FaYelp } from 'react-icons/fa';
import { BiPhoneCall } from 'react-icons/bi';
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <div className="footer-container">
      <img
        alt="logo"
        src="https://s3.us-west-2.amazonaws.com/moveviking.com/images/logo-viking-footer.png"
      ></img>
      <button className="button">
        <Link className="link" to="/contact-us">
          CONTACT US
        </Link>
      </button>
      <div className="phone-container">
        <a
          href="tel:+18188454647"
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              cursor: 'pointer',
              gap: 5,
            }}
          >
            <BiPhoneCall style={{ fontSize: '22px', color: 'black' }} />
            <span className="phone-number"> (818) 845 46 47</span>
          </div>
        </a>
        <a
          href="mailto:vikingmovingandrelocation@gmail.com"
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          {' '}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 10,
              cursor: 'pointer',
            }}
          >
            <span
              className="email-icon"
              style={{ fontSize: '26px', color: 'white', paddingTop: 5 }}
            >
              ✉️
            </span>
            <h4>vikingmovingandrelocation@gmail.com</h4>
          </div>
        </a>
        <h4>Long Beach, CA</h4>
      </div>
      <div className="social-media-container">
        <a href="https://www.yelp.com/biz/viking-moving-and-relocation-services-long-beach">
          <FaYelp style={{ color: '#746c61' }} size={30} />
        </a>
        <a href="https://goo.gl/maps/mr66i3sJsSq2ZMeZ7">
          <GoLocation style={{ color: '#746c61' }} size={30} />
        </a>
      </div>
    </div>
  );
};

export default Footer;
