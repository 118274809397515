import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import './navigation.styles.scss';
import { Link } from 'react-router-dom';

const MobileNavigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuItemClick = () => {
    setIsOpen(false);
  };

  return (
    <div className="navbar">
      <div className="navbar__toggle" onClick={handleMenuToggle}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </div>
      {isOpen && (
        <div className="menu">
          <ul className={`navbar__menu ${isOpen ? 'active' : ''}`}>
            <Link className="navbar_item" to="/" onClick={handleMenuItemClick}>
              Home
            </Link>
            <Link
              className="navbar_item"
              to="/about-us"
              onClick={handleMenuItemClick}
            >
              About
            </Link>
            <Link
              className="navbar_item"
              to="/moving-services"
              onClick={handleMenuItemClick}
            >
              Moving Services
            </Link>
            <Link
              className="navbar_item"
              to="/packing-services"
              onClick={handleMenuItemClick}
            >
              Packing Services
            </Link>
            <Link
              className="navbar_item"
              to="/contact-us"
              onClick={handleMenuItemClick}
            >
              Contact Us
            </Link>
          </ul>
        </div>
      )}
    </div>
  );
};

export default MobileNavigation;
