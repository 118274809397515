import React, { useCallback } from 'react';
import { useState } from 'react';
import './contact-us.styles.scss';
import '../main-text.styles.scss';
import axios from 'axios';

const defaultValue = {
  name: '',
  phone: '',
  email: '',
  moveFrom: '',
  moveTo: '',
  moveDate: '',
  details: '',
  aptSize: '',
  sendFrom: 'booking@wehomoving.com',
  website: 'www.moveviking.com',
};

const url =
  'https://g4dxkdfal9.execute-api.us-west-2.amazonaws.com/wehomoving-oregon/request-quote';

function ContactUs() {
  const [contactFormObject, setContactFormObject] = useState(defaultValue);
  const {
    name,
    phone,
    email,
    moveFrom,
    moveTo,
    moveDate,
    aptSize,
    details,
  } = contactFormObject;

  const resetFields = useCallback(() => {
    setContactFormObject(defaultValue);
  }, []);

  const sendForm = async () => {
    try {
      const response = await axios.post(url, contactFormObject);

      console.log('Email sent:', response.data);
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendForm();
    resetFields();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactFormObject({ ...contactFormObject, [name]: value });
  };

  return (
    <div className="contact-us-container">
      <span
        style={{
          textAlign: 'start',
          color: 'white',
          fontSize: 40,
          opacity: '0.8',
          paddingLeft: 5,
          paddingTop: 10,
        }}
      >
        Get Started With a Free Phone or Online Quote
      </span>

      <form className="contact-container" onSubmit={handleSubmit}>
        <input
          name="name"
          placeholder="name"
          onChange={handleChange}
          label="name"
          type="text"
          value={name}
          className="contact-box-first-name"
          required
        />
        <input
          label="email"
          type="email"
          placeholder="email"
          value={email}
          className="contact-box-field-email"
          onChange={handleChange}
          name="email"
          required
        />
        <input
          name="phone"
          placeholder="phone number"
          label="phone-number"
          type="text"
          value={phone}
          className="contact-box-field-phone"
          onChange={handleChange}
          required
        />
        <input
          placeholder="move from"
          name="moveFrom"
          value={moveFrom}
          onChange={handleChange}
          type="text"
          label="move from"
          className="contact-box-field-moveFrom"
        />
        <input
          placeholder="move to"
          name="moveTo"
          value={moveTo}
          onChange={handleChange}
          type="text"
          label="move to"
          className="contact-box-field-moveto"
        />
        <input
          placeholder="move date"
          name="moveDate"
          value={moveDate}
          onChange={handleChange}
          type="date"
          label="date"
          className="contact-box-field-movedate"
          required
        />
        <input
          placeholder="apartment size"
          name="aptSize"
          value={aptSize}
          onChange={handleChange}
          type="text"
          label="aptSize"
          className="contact-box-field-aptSize"
        />
        <input
          placeholder="details"
          name="details"
          value={details}
          label="details"
          className="details-box"
          type="text"
          onChange={handleChange}
        />
        <button type="submit" className="submit-button">
          SEND
        </button>
      </form>
    </div>
  );
}

export default ContactUs;
