import React, { useState, useEffect, Fragment } from 'react';
import { Outlet, Link } from 'react-router-dom';
import MobileNavigation from './mobileNavigation';
import './navigation.styles.scss';

const Navigation = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Fragment>
      {isMobile ? (
        <MobileNavigation />
      ) : (
        <div className="navigation-container">
          <div className="text-container-header">
            <span className="navigation-header">
              Viking Moving And Relocation
            </span>
          </div>
          <div className="nav-links-container">
            <Link className="nav-link" to="/">
              Home
            </Link>
            <Link className="nav-link" to="/about-us">
              About
            </Link>
            <Link className="nav-link" to="/moving-services">
              Moving Serviced
            </Link>
            <Link className="nav-link" to="/packing-services">
              Packing Services
            </Link>
            <Link className="nav-link" to="/contact-us">
              Contact Us
            </Link>
          </div>
        </div>
      )}
      <Outlet />
    </Fragment>
  );
};

export default Navigation;
